
@import '~angular-material/angular-material';
@import'lib/tables';
@import'lib/transitions';
@import'lib/loading';
body { font-family: 'Roboto', sans-serif;}
.clickable {
    cursor: pointer;
}

.parentItem {
    // background-color: rgb(96, 125, 139);
    // color: #fff;
    font-weight: 800;
}

.activeItem {
    background-color: rgb(96, 125, 139);
    color: #fff;
}

