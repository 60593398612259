.service-image-height {
    height: 75px;
    width:auto;
}

.service-image-height-small {
    height: 40px;
    width:auto;
}

.service-image-width {
    height: auto;
    width:150px;
}


.header-image-height {
    height: 150px;
    width:auto;
}
.header-image-width {
    height: auto;
    width:300px;
}

.logo-image-height {
    height: 100px;
    width:auto;
}
.logo-image-width {
    height: auto;
    width:150px;
}



/* basic styling for entering and leaving */
/* left and right added to ensure full width */
.slide.ng-enter,
.slide.ng-leave      { position:absolute; left:30px; right:30px;
    transition:0.5s all ease; -moz-transition:0.5s all ease; -webkit-transition:0.5s all ease; 
}
    
/* enter animation */
.slide.ng-enter            { 
    -webkit-animation:slideInRight 0.5s both ease;
    -moz-animation:slideInRight 0.5s both ease;
    animation:slideInRight 0.5s both ease; 
}

/* leave animation */
.slide.ng-leave            { 
    -webkit-animation:slideOutLeft 0.5s both ease;
    -moz-animation:slideOutLeft 0.5s both ease;
    animation:slideOutLeft 0.5s both ease;   
}