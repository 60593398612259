.table {
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  background-color: #fff; }
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .table > thead > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > th,
    .table > tbody > tr > td,
    .table > tfoot > tr > th,
    .table > tfoot > tr > td {
      text-align: left;
      padding: 1.2rem;
      vertical-align: top;
      border-top: 0;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
  .table > thead > tr > th {
    font-weight: 400;
    color: #757575;
    vertical-align: bottom;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .table > caption + thead > tr:first-child > th,
  .table > caption + thead > tr:first-child > td,
  .table > colgroup + thead > tr:first-child > th,
  .table > colgroup + thead > tr:first-child > td,
  .table > thead:first-child > tr:first-child > th,
  .table > thead:first-child > tr:first-child > td {
    border-top: 0; }
  .table > tbody + tbody {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
  .table .table {
    background-color: #fff; }
  .table .no-border {
    border: 0; }

.table-bordered {
  border: 0; }
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tbody > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > tfoot > tr > td {
    border: 0;
    border-bottom: 1px solid #e0e0e0; }
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-bottom-width: 2px; }

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: rgba(0, 0, 0, 0.12); }

@media screen and (max-width: 768px) {
  .table-responsive-vertical > .table {
    margin-bottom: 0;
    background-color: transparent; }
    .table-responsive-vertical > .table > thead,
    .table-responsive-vertical > .table > tfoot {
      display: none; }
    .table-responsive-vertical > .table > tbody {
      display: block; }
      .table-responsive-vertical > .table > tbody > tr {
        display: block;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        margin-bottom: 1.2rem; }
        .table-responsive-vertical > .table > tbody > tr > td {
          background-color: #fff;
          display: block;
          vertical-align: middle;
          text-align: right; }
        .table-responsive-vertical > .table > tbody > tr > td[data-title]:before {
          content: attr(data-title);
          float: left;
          font-size: inherit;
          font-weight: 400;
          color: #757575;
          padding-right: 10px; }
  .table-responsive-vertical.shadow-z-1 {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
    .table-responsive-vertical.shadow-z-1 > .table > tbody > tr {
      border: none; }
  .table-responsive-vertical > .table-bordered {
    border: 0; }
    .table-responsive-vertical > .table-bordered > tbody > tr > td {
      border: 0;
      border-bottom: 1px solid #e0e0e0; }
    .table-responsive-vertical > .table-bordered > tbody > tr > td:last-child {
      border-bottom: 0; }
  .table-responsive-vertical > .table-striped > tbody > tr > td,
  .table-responsive-vertical > .table-striped > tbody > tr:nth-child(odd) {
    background-color: #fff; }
  .table-responsive-vertical > .table-striped > tbody > tr > td:nth-child(odd) {
    background-color: #f5f5f5; }
  .table-responsive-vertical > .table-hover > tbody > tr:hover > td,
  .table-responsive-vertical > .table-hover > tbody > tr:hover {
    background-color: #fff; }
  .table-responsive-vertical > .table-hover > tbody > tr > td:hover {
    background-color: rgba(0, 0, 0, 0.12); } }

body {
  color: #7A7A7A; }

.toolbar-button {
  min-width: 20px; }

.share-label {
  vert-align: middle;
  margin-left: 10px; }

.page-content {
  background-color: #ECECEC; }

.widget-progress {
  width: 95% !important;
  margin-left: 10px; }

.capitalize {
  text-transform: capitalize; }

.img-circle {
  height: 50px;
  border-radius: 50%;
  margin: 0 10px; }

.messages {
  position: relative; }
  .messages-content {
    height: 100vh; }

.notifications-label {
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  font-size: 12px;
  top: 2px;
  right: 2px;
  background-color: #E75753; }

.panel-widget {
  background-color: white; }
  .panel-widget-toolbar {
    box-shadow: none; }
  .panel-widget-title {
    color: #7A7A7A; }

.options {
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s; }

.options.ng-hide {
  opacity: 0; }

.fixed-height-widget {
  height: 310px; }

.donut-chart-title {
  text-align: center; }

.custom-tooltip {
  font-size: 22px;
  color: #7A7A7A;
  text-align: center; }

md-radio-group {
  border: none; }

md-icon {
  height: auto; }

md-sidenav.md-locked-open.md-sidenav-left {
  width: 310px;
  min-width: 310px;
  max-width: 310px; }

a.md-button.md-warn.md-raised, a.md-button.md-warn.md-fab, .md-button.md-warn.md-raised, .md-button.md-warn.md-fab {
  color: white; }

md-radio-button.md-accent.md-checked .md-label span {
  color: #009688; }

.md-toolbar-tools h1 {
  font-size: 22px; }

.material-icons {
  vertical-align: middle; }

.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

.nvd3.nv-pie .nv-pie-title {
  fill: #444444;
  font-weight: 600;
  font-size: 22px !important; }

.nvtooltip {
  background-color: transparent;
  border: none;
  font-weight: 600; }

.orange .md-bar2 {
  background-color: orange; }

.orange .md-container {
  background-color: #ffecc9; }

.top-header {
  border-top: 6px solid tomato; }
  .top-header > .header-text {
    padding: .3em;
    color: #fff; }

.input-decorated {
  border-bottom: 2px solid tomato; }

/*# sourceMappingURL=admin.css.map */
